import React from 'react';

function ColorList(props) {
    return (
        [
            ["cb997e", "ddbea9", "ffe8d6", "b7b7a4", "a5a58d", "6b705c"],
            ["fec5bb", "fcd5ce", "fae1dd", "f8edeb", "e8e8e4", "d8e2dc", "ece4db", "ffe5d9", "ffd7ba", "fec89a"],
            ["001219", "005f73", "0a9396", "94d2bd", "e9d8a6", "ee9b00", "ca6702", "bb3e03", "ae2012", "9b2226"],
            ["03045e", "023e8a", "0077b6", "0096c7", "00b4d8", "48cae4", "90e0ef", "ade8f4", "caf0f8"],
            ["f72585", "b5179e", "7209b7", "560bad", "480ca8", "3a0ca3", "3f37c9", "4361ee", "4895ef", "4cc9f0"],
            ["fbf8cc", "fde4cf", "ffcfd2", "f1c0e8", "cfbaf0", "a3c4f3", "90dbf4", "8eecf5", "98f5e1", "b9fbc0"],
            ["264653", "2a9d8f", "e9c46a", "f4a261", "e76f51"],
            ["ede0d4", "e6ccb2", "ddb892", "b08968", "7f5539", "9c6644"],
            ["e63946", "f1faee", "a8dadc", "457b9d", "1d3557"],
            ["d8f3dc", "b7e4c7", "95d5b2", "74c69d", "52b788", "40916c", "2d6a4f", "1b4332", "081c15"],
            ["f94144", "f3722c", "f8961e", "f9844a", "f9c74f", "90be6d", "43aa8b", "4d908e", "577590", "277da1"],
            ["8ecae6", "219ebc", "023047", "ffb703", "fb8500"],
            ["cdb4db", "ffc8dd", "ffafcc", "bde0fe", "a2d2ff"],
            ["f4f1de", "e07a5f", "3d405b", "81b29a", "f2cc8f"],
            ["ccd5ae", "e9edc9", "fefae0", "faedcd", "d4a373"],
            ["03071e", "370617", "6a040f", "9d0208", "d00000", "dc2f02", "e85d04", "f48c06", "faa307", "ffba08"],
            ["000000", "14213d", "fca311", "e5e5e5", "ffccff"],
            ["d9ed92", "b5e48c", "99d98c", "76c893", "52b69a", "34a0a4", "168aad", "1a759f", "1e6091", "184e77"],
            ["f8f9fa", "e9ecef", "dee2e6", "ced4da", "adb5bd", "6c757d", "495057", "343a40", "212529"],
            ["ffcdb2", "ffb4a2", "e5989b", "b5838d", "6d6875"],
            ["606c38", "283618", "fefae0", "dda15e", "bc6c25"],
            ["227c9d", "17c3b2", "ffcb77", "fef9ef", "fe6d73"],
            ["e9f5db", "cfe1b9", "b5c99a", "97a97c", "87986a", "718355"],
            ["003049", "d62828", "f77f00", "fcbf49", "eae2b7"],
            ["f6bd60", "f7ede2", "f5cac3", "84a59d", "f28482"],
            ["10002b", "240046", "3c096c", "5a189a", "7b2cbf", "9d4edd", "c77dff", "e0aaff"],
            ["ffadad", "ffd6a5", "fdffb6", "caffbf", "9bf6ff", "a0c4ff", "bdb2ff", "ffc6ff", "ffccff"],
            ["3d5a80", "98c1d9", "e0fbfc", "ee6c4d", "293241"],
            ["000814", "001d3d", "003566", "ffc300", "ffd60a"],
            ["b98b73", "cb997e", "ddbea9", "ffe8d6", "d4c7b0", "b7b7a4", "a5a58d", "6b705c", "3f4238"],
            ["0b090a", "161a1d", "660708", "a4161a", "ba181b", "e5383b", "b1a7a6", "d3d3d3", "f5f3f4", "ffccff"],
            ["edede9", "d6ccc2", "f5ebe0", "e3d5ca", "d5bdaf"],
            ["22223b", "4a4e69", "9a8c98", "c9ada7", "f2e9e4"],
            ["7400b8", "6930c3", "5e60ce", "5390d9", "4ea8de", "48bfe3", "56cfe1", "64dfdf", "72efdd", "80ffdb"],
            ["ff9f1c", "ffbf69", "ffccff", "cbf3f0", "2ec4b6"],
            ["5f0f40", "9a031e", "fb8b24", "e36414", "0f4c5c"],
            ["2b2d42", "8d99ae", "edf2f4", "ef233c", "d90429"],
            ["006d77", "83c5be", "edf6f9", "ffddd2", "e29578"],
            ["f72585", "7209b7", "3a0ca3", "4361ee", "4cc9f0"],
            ["fffcf2", "ccc5b9", "403d39", "252422", "eb5e28"],
            ["588b8b", "ffccff", "ffd5c2", "f28f3b", "c8553d"],
            ["0466c8", "0353a4", "023e7d", "002855", "001845", "001233", "33415c", "5c677d", "7d8597", "979dac"],
            ["eddcd2", "fff1e6", "fde2e4", "fad2e1", "c5dedd", "dbe7e4", "f0efeb", "d6e2e9", "bcd4e6", "99c1de"],
            ["e8a598", "ffb5a7", "fec5bb", "fcd5ce", "fae1dd", "f8edeb", "f9e5d8", "f9dcc4", "fcd2af", "fec89a"],
            ["053c5e", "1d3958", "353652", "4c334d", "643047", "7c2e41", "942b3b", "ab2836", "c32530", "db222a"],
            ["072ac8", "1e96fc", "a2d6f9", "fcf300", "ffc600"],
            ["e3f2fd", "bbdefb", "90caf9", "64b5f6", "42a5f5", "2196f3", "1e88e5", "1976d2", "1565c0", "0d47a1"],
            ["ff99c8", "fcf6bd", "d0f4de", "a9def9", "e4c1f9"],
            ["ffac81", "ff928b", "fec3a6", "efe9ae", "cdeac0"],
            ["0a2463", "3e92cc", "ffccff", "d8315b", "1e1b18"],
            ["053c5e", "1d3958", "353652", "4c334d", "643047", "7c2e41", "942b3b", "ab2836", "c32530", "db222a"],
            ["231942", "5e548e", "9f86c0", "be95c4", "e0b1cb"],
            ["eddcd2", "fff1e6", "fde2e4", "fad2e1", "c5dedd", "dbe7e4", "f0efeb", "d6e2e9", "bcd4e6", "99c1de"],
            ["004733", "2b6a4d", "568d66", "a5c1ae", "ffccff", "dcdfe5", "df8080", "cb0b0a", "ad080f", "8e0413"],
            ["669900", "99cc33", "ccee66", "006699", "3399cc", "990066", "cc3399", "ff6600", "ff9900", "ffcc00"],
            ["0466c8", "0353a4", "023e7d", "002855", "001845", "001233", "38b000", "70e000", "9ef01a", "ccff33"],
            ["152528", "08485e", "3cb0cd", "7cd5f3", "b4e7f8", "adffbc", "1bee9a", "21c063", "229631", "0c5a23"],
            ["71093b", "990b52", "cb8b15", "eaaa34", "ffccff", "f1f4f9", "749ed2", "467ec3", "023578", "022450"],
            ["03045e", "032174", "023e8a", "0077b6", "0096c7", "00b4d8", "48cae4", "90e0ef", "ade8f4", "caf0f8"],
            ["144366", "19598a", "006ba1", "3ba1da", "42bfc6", "49dcb1", "affee5", "ffe0e6", "ef798a", "ff6b6c"],
            ["fadde1", "ffc4d6", "ffa6c1", "ff87ab", "ff5d8f", "ff97b7", "ffacc5", "ffcad4", "f4acb7"],
            ["ffe169", "fad643", "edc531", "dbb42c", "c9a227", "b69121", "a47e1b", "926c15", "805b10", "76520e"],
            ["f72585", "b5179e", "7209b7", "560bad", "480ca8", "3a0ca3", "3f37c9", "4361ee", "4895ef", "4cc9f0"],
            ["250902", "38040e", "640d14", "800e13", "ad2831"],
            ["54478c", "2c699a", "048ba8", "0db39e", "16db93", "83e377", "b9e769", "efea5a", "f1c453", "f29e4c"],
            ["00296b", "003f88", "00509d", "fdc500", "ffd500"],
            ["132a13", "31572c", "4f772d", "90a955", "ecf39e"],
            ["001427", "708d81", "f4d58d", "bf0603", "8d0801"],
            ["143601", "1a4301", "245501", "538d22", "73a942", "aad576"],


        ]

    );
}

export default ColorList;