export const getLabel = (id, list) => {
  var item = list.find((x) => x.value == id);
  if (item) return item.label;
  return "";
};
export const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
