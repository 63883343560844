var server = process.env.REACT_APP_SERVER;
// server = "http://localhost:4001";

// build for server test
// server = "https://testapi.cubeloyalty.vn";
// const api_url = `${server}/api`;
// const wss_url = "https://realtime.cubeloyalty.vn";
// const customer_client = "https://testcustomer.cubetiger.app";
// const admin_client = "https://testapp.cubeloyalty.vn";

//build for server chính
server = "https://api.cubeloyalty.vn";
const api_url = `${server}/api`;
const wss_url = "https://realtime.cubeloyalty.vn";
const customer_client = "https://customer.cubetiger.app";
const admin_client = "https://app.cubeloyalty.vn";

const media_url = server;
export { api_url, media_url, wss_url, customer_client, admin_client, server };
