import React, { useContext, useState } from "react";
import { Table, Space, Typography, Form, InputNumber, Input, Switch } from "antd";
import ProductContext from "context/product/ProductContext";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useEffect } from "react";

const { Text } = Typography;
function VariantTable({ setFieldValue }) {
  const productContext = useContext(ProductContext);
  const mode = window.location.pathname.split("/")[3];

  const [activeList, setActiveList] = useState([]);
  const [dataSource, setDataSource] = useState([]);

  useEffect(() => {
    if (!productContext.productItem.is_variant) {
      setDataSource([
        {
          is_active: true,
          quantity: productContext.productItem && productContext.productItem.skus?.length > 0 ? productContext.productItem.skus[0]?.quantity : 0,
          original_price: productContext.productItem && productContext.productItem.skus?.length > 0 ? productContext.productItem.skus[0]?.original_price : 0,
          sale_price: productContext.productItem && productContext.productItem.skus?.length > 0 ? productContext.productItem.skus[0]?.sale_price : 0,
          sku: productContext.productItem && productContext.productItem.skus?.length > 0 ? productContext.productItem.skus[0]?.sku : "",
        },
      ]);
    } else {
      // debugger;
      if (productContext.productItem.variants.length > 0) {
        var arr = [];
        productContext.productItem.variants.forEach((item) => {
          arr.push(item.options);
        });
        const combined = combineArrays(arr);
        var newDataSource = [];
        combined.forEach((item) => {
          var record = {};
          item.forEach((i, index) => {
            if (index === 0) {
              record["variant_title"] = i.value;
              record["variant_name"] = i.value;
            } else {
              record["variant_title"] += ` ${i.value}`;
              record["variant_name"] += ` ${i.value}`;
            }

            record[`option${index + 1}`] = i.id;
          });
          var find_variant = productContext.productItem.skus.find((item) => {
            return item.variant_title.replace(productContext.productItem.name, "").trim() === record["variant_name"];
          });

          // console.log(productContext.productItem.skus[0].variant_title.replace(productContext.productItem.name, "").trim());
          // console.log(productContext.productItem.skus[0].variant_title);
          newDataSource.push({
            ...record,
            quantity: find_variant ? find_variant.quantity : 0,
            original_price: find_variant ? find_variant.original_price : 0,
            sale_price: find_variant ? find_variant.sale_price : 0,
            sku: find_variant ? find_variant.sku : "",
            is_active: find_variant ? (find_variant.is_active === false ? false : true) : true,
          });
        });
        setDataSource(newDataSource);
        // setDataSource([
        //   {
        //     quantity: 0,
        //     original_price: 0,
        //     sale_price: 0,
        //     sku: "",
        //   },
        // ]);
      }
    }
  }, [productContext]);

  useEffect(() => {
    if (dataSource.length > 0) {
      // console.log(dataSource);
      var list = dataSource.map((item) => {
        return {
          variant_name: item.variant_name,
          is_active: item.is_active === true || item.is_active === false ? item.is_active : !item.sale_price || !item.original_price ? false : true,
        };
      });
      setActiveList(list);
      list.forEach((item, index) => {
        setFieldValue(["skus", index, "is_active"], item.is_active);
      });
    }
  }, [dataSource]);

  function combineArrays(arrays) {
    if (arrays.length === 0) {
      return [[]];
    }

    const firstArray = arrays[0];
    const remainingArrays = arrays.slice(1);

    const combinedArrays = combineArrays(remainingArrays);

    const result = [];
    for (let i = 0; i < firstArray.length; i++) {
      for (let j = 0; j < combinedArrays.length; j++) {
        result.push([firstArray[i], ...combinedArrays[j]]);
      }
    }

    return result;
  }

  const defaultColumns = [
    {
      title: "",
      dataIndex: "is_active",
      render: (text, record, index) => {
        // console.log("ehehhe");
        // var is_active = false;
        // if (text === false || text === true) {
        //   is_active = text;
        // } else {
        //   if (!record.sale_price || !record.original_price) {
        //     is_active = false;
        //   } else {
        //     is_active = true;
        //   }
        // }

        // console.log(activeList);

        // console.log(is_active);
        // console.log(record);
        var filter = activeList.filter((item) => {
          return item.is_active;
        });
        return (
          <Form.Item style={{ margin: 0 }} name={["skus", index, "is_active"]} valuePropName="checked">
            <Switch
              onChange={() => {
                var new_list = [...activeList];
                new_list[index].is_active = !new_list[index].is_active;
                setActiveList(new_list);
              }}
              disabled={dataSource.length < 2 || (filter.length < 2 && filter.length > 0 && filter[0].variant_name === record.variant_name) ? true : false}
            />
          </Form.Item>
        );
      },
    },

    {
      title: (
        <Space>
          <Text>Biến thể</Text>
          <QuestionCircleOutlined />
        </Space>
      ),
      dataIndex: "variant_name",
      render: (text, record, index) => {
        if (!productContext.productItem.is_variant) {
          return "Mặc định";
        } else {
          setFieldValue(["skus", index, "variant_title"], record.variant_title);
          if (record.option1) {
            setFieldValue(["skus", index, "option1"], record.option1);
          }
          if (record.option2) {
            setFieldValue(["skus", index, "option2"], record.option2);
          }
          if (record.option3) {
            setFieldValue(["skus", index, "option3"], record.option3);
          }

          return (
            <>
              {text}
              <Form.Item hidden style={{ margin: 0 }} initialValue={record.variant_title} name={[`skus`, index, "variant_title"]}>
                <Input />
              </Form.Item>
              {/* {"option1:" + record.option1} */}
              <Form.Item hidden style={{ margin: 0 }} initialValue={record.option1} name={[`skus`, index, "option1"]}>
                <Input />
              </Form.Item>
              {/* {"option2:" + record.option2} */}
              {record.option2 && (
                <Form.Item hidden initialValue={record.option2} style={{ margin: 0 }} name={[`skus`, index, "option2"]}>
                  <Input />
                </Form.Item>
              )}
              {/* {"option3:" + record.option3} */}
              {record.option3 && (
                <Form.Item hidden initialValue={record.option3} style={{ margin: 0 }} name={[`skus`, index, "option3"]}>
                  <Input />
                </Form.Item>
              )}
            </>
          );
        }
      },
    },
    {
      title: (
        <Space>
          <Text>Giá bán</Text>
          <QuestionCircleOutlined />
        </Space>
      ),
      dataIndex: "sale_price",
      editable: true,
      render: (text, record, index) => {
        if (text) {
          setFieldValue(["skus", index, "sale_price"], text);
        }
        return (
          <Form.Item rules={[{ required: true, message: "Vui lòng nhập giá bán" }]} style={{ margin: 0 }} name={[`skus`, index, "sale_price"]}>
            <InputNumber
              style={{ width: "100%" }}
              defaultValue={text}
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            ></InputNumber>
          </Form.Item>
        );
      },
    },
    {
      title: (
        <Space>
          <Text>Giá gốc</Text>
          <QuestionCircleOutlined />
        </Space>
      ),
      dataIndex: "original_price",
      editable: true,
      render: (text, record, index) => {
        if (text) {
          setFieldValue(["skus", index, "original_price"], text);
        }
        return (
          <Form.Item style={{ margin: 0 }} rules={[{ required: true, message: "Vui lòng nhập giá gốc" }]} name={[`skus`, index, "original_price"]}>
            <InputNumber
              defaultValue={text}
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
              style={{ width: "100%" }}
            ></InputNumber>
          </Form.Item>
        );
      },
    },
    {
      title: (
        <Space>
          <Text>Số lượng</Text>
          <QuestionCircleOutlined />
        </Space>
      ),
      dataIndex: "quantity",
      editable: true,
      render: (text, record, index) => {
        if (text) {
          setFieldValue(["skus", index, "quantity"], text);
        }

        return (
          <Form.Item rules={[{ required: true, message: "Vui lòng nhập số lượng" }]} style={{ margin: 0 }} name={[`skus`, index, "quantity"]}>
            <InputNumber style={{ width: "100%" }} defaultValue={text} min={0}></InputNumber>
          </Form.Item>
        );
      },
    },
    {
      title: (
        <Space>
          <Text>SKU người bán</Text>
          <QuestionCircleOutlined />
        </Space>
      ),
      dataIndex: "sku",
      editable: true,
      render: (text, record, index) => {
        if (text) {
          setFieldValue(["skus", index, "sku"], text);
        }

        return (
          <Form.Item style={{ margin: 0 }} name={[`skus`, index, "sku"]}>
            <Input defaultValue={text} placeholder="SKU người bán"></Input>
          </Form.Item>
        );
      },
    },
  ];

  return <Table rowClassName={() => "editable-row"} dataSource={dataSource} pagination={false} columns={defaultColumns} />;
}

export default VariantTable;
